<template>
  <div class="table-column">
    <div v-if="showTitle" class="column-title">
      {{ $t('matches.single') }}
    </div>

    <div class="column-content">
      <div v-if="shortMode" class="tffa">
        <template v-if="match.results && match.results.place">
          <div class="tffa-place">
            {{ match.results.place }} {{ $t('places.single') }}
          </div>
          <div class="tffa-info">
            {{ match.name }}, {{ match.round.name }}
          </div>
        </template>
        <div v-else class="tffa-place">{{ match.name }}</div>
      </div>

      <div v-else class="vs">
        <BaseTeamLink
          v-if="teamHome.hash"
          :is-reversed="true"
          :hash="teamHome.hash"
          class="team"
          :class="teamHome.status"
        />
        <div v-else class="team" :class="teamHome.status">
          &mdash;
        </div>

        <span class="divider">
          <template v-if="isPointsVisible">
            <span class="points" :class="teamHome.status">
              {{ teamHome.points }}
            </span>
            :
            <span class="points" :class="teamVisitor.status">
              {{ teamVisitor.points }}
            </span>
          </template>
          <span v-else class="vs">VS</span>
        </span>

        <BaseTeamLink
          v-if="teamVisitor.hash"
          :hash="teamVisitor.hash"
          class="team"
          :class="teamVisitor.status"
        />
        <div v-else class="team" :class="teamVisitor.status">
          &mdash;
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTeamLink from '@components/BaseComponents/BaseTeamLink.vue';

export default {
  name: 'CellMatch',

  components: {
    BaseTeamLink,
  },

  props: {
    match: {
      type: Object,
      required: true,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    shortMode: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    teamHome() {
      return this.formatTeam(1);
    },
    teamVisitor() {
      return this.formatTeam(2);
    },
    isPointsVisible() {
      return (
        this.match.hashWinner ||
        this.match.idWinner ||
        this.match?.technicalDefeatHashList?.length
      );
    },
  },

  methods: {
    formatTeam(side) {
      if (this.isTffa) {
        return null;
      }

      const team = this.match[`team${side}`];

      if (!team?.hash || team?.hash === this.freeSlot.hash) {
        return {
          status: 'lose',
          points: 0,
        };
      }

      const points = this.match.technicalDefeatHashList?.includes(
        team.hash,
      )
        ? this.$t('matches.tech_shortDefeat')
        : this.match[`pointsTeam${side}`];

      return {
        ...team,
        points,
        status: this.match.idWinner === team.id ? 'win' : 'lose',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.table-column {
  overflow: hidden;
}

.column-content {
  width: 100%;
  @include max-laptop() {
    overflow: hidden;
  }
}

.tffa {
  @include min-laptop() {
    text-align: center;
  }

  &-place {
    font-size: 15px;
    font-weight: bold;
    line-height: 1.6;
    color: white;
  }

  &-info {
    font-size: 13px;
    line-height: 1.85;
    color: rgba(white, 0.54);
  }
}

.vs {
  display: flex;
  align-items: center;
  flex-grow: 1;
  @include min-laptop() {
    justify-content: center;
  }

  .team {
    &:first-child {
      text-align: right;
    }

    @include min-laptop() {
      flex-basis: calc((90% - 90px) / 2);
    }

    @include max-laptop() {
      ::v-deep .team {
        &-icon {
          display: none;

          & + .team-name {
            margin-left: 0;
          }
        }
      }
    }
  }

  .divider {
    display: flex;
    justify-content: center;
    color: rgba(white, 0.4);
    @include min-laptop() {
      padding: 0 4px;
      width: 90px;
      text-align: left;
    }
    @include max-laptop() {
      padding: 0 8px;
    }
  }

  .points {
    padding: 0 4px;
    font-weight: bold;
  }
}

.win {
  color: var(--main-color-white);
}

.lose {
  color: var(--additional-color-gray);
}
</style>
